import React from 'react';
import { Row, Col, Menu, Icon, List, Card, Anchor, Avatar, Input } from 'antd';
// import HeaderSearch from 'ant-design-pro/lib/HeaderSearch';
import Header from './header';
import Layout from './layout';
import { Link } from "gatsby"

const CompanyLayout = (props) => {
    return (
    <Layout>
        <div style={{ paddingTop:'60px', paddingBottom:'60px', backgroundColor:'#fff',}}>
            <Row style={{ paddingTop:'50px', width:'100%'}} justify='center'>
                <Col sm={22} md={18} lg={16}>
                    <h2 style={{fontSize:'28px', fontWeight:'400', lineHeight:'1.2', marginBottom:'12px', color:'#495057', whiteSpace:'prewrap'}}>
                    <span style={{fontWeight:600}}>Company</span>
                    </h2>
                </Col>
                <Col sm={22} md={18} lg={16} style={{ backgroundColor:'#fff',}}>
                    {props.children}
                </Col>
            </Row>
        </div>
    </Layout>
    )
}

export default CompanyLayout;
