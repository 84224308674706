import React from 'react';
import CompanyLayout from '../../components/company';
import { Card, Row, Col, Menu } from 'antd';
import { Link } from 'gatsby';
import SEO from '../../components/seo';

const Privacy = (props) => {
    return (
      <CompanyLayout {...props}>
        <SEO title="About" description='Company - About' />
        <h2 style={{fontSize:'28px', fontWeight:'400', lineHeight:'1.2', marginBottom:'12px', color:'#495057', whiteSpace:'prewrap'}}>
          About <span style={{fontWeight:600}}>Us</span>
        </h2>
        <div style={{height:'100%', paddingBottom:'150px'}}>
          <Card>
            <p>RealtyStack was created to make real estate and property management simple. Entrepreneurs, Investors, Landlords, and Residents can come together to streamline the process and make renting and managing properties easier.</p>
            <h3>Our Story</h3>
            Since 2018 RealtyStack has been developed to deliver on our vison of making property management easier. Offering as many services as possible to handle the boring stuff so you can focus on what you do best and leave the rest to us.
          </Card>
        </div>
      </CompanyLayout>
    )
}

export default Privacy;
